<template>
  <div class="mt_69 bg_F8F8F8">
    <Header :activeName="activeName" />
    <div class="flex justify-center">
      <div class="fixed_width home_title mt_20">首页>加入我们>全部职位</div>
    </div>

    <div class="flex justify-center">
      <div class="fixed_width mr_60">
        <el-row :gutter="29">
          <el-col
            @mouseenter="item.active = true"
            @mouseleave="item.active = false"
            :span="12"
            v-for="(item, index) in positionList"
            :key="index"
            class="mt_20"
          >
            <div
              class="position_box cursor-pointer"
              @click="handleToDetail(item)"
              :class="item.active == true ? 'bg_2A78FF' : ''"
            >
              <el-row>
                <el-col :span="19">
                  <div class="pt_20 pl_30 mr_20">
                    <div class="text-overflow1 fz_22 fc_111C2F">
                      {{ item.title }}
                    </div>
                    <div class="text-overflow1 mt_20 fz_18 fc_92959A">
                      {{ item.seoDescription }}
                    </div>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="flex center fz_18 mt_detail fc_92959A">
                    了解详情
                    <i class="el-icon-arrow-right ml_10"></i>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="flex justify-center mt_30 mb_30">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <Bottom />
  </div>
</template>

<script>
import { getNewsPage } from "@/api/home";
import Bottom from "@/components/bottom/index";
import Header from "@/components/header/index";
export default {
  components: { Bottom, Header },
  data() {
    return {
      banner: require("@/assets/images/about.jpg"),
      positionId: "",
      positionCurrent: 1,
      positionSize: 10,
      positionList: [],
      environmentId: "",
      environmentList: [],
      total: 0,
      info: {},
      imgViewDlgVisible: false,
      ImgUrl: "",
      activeName: "加入我们",
    };
  },

  mounted() {
    this.getNewsList();
  },
  methods: {
    handlePreview(img) {
      this.ImgUrl = img;
      this.imgViewDlgVisible = true;
    },
    getNewsList() {
      if (sessionStorage.getItem("info")) {
        this.info = JSON.parse(sessionStorage.getItem("info"));
        this.info.children.forEach((item) => {
          if (item.title == "招聘职位") {
            this.positionId = item.id;
          } else if (item.title == "办公环境") {
            this.environmentId = item.id;
          }
        });
        this.info.img = this.info.img
          ? this.info.img
          : require("@/assets/images/about.jpg");
        if (this.$route.query.activeName) {
          this.activeMenuName = this.$route.query.activeName;
        } else {
          this.activeMenuName = this.info.children[0].title;
        }
        this.getpositionList();
      } else {
        this.getInfo();
      }
    },

    getpositionList() {
      const obj = {
        id: this.positionId,
        current: this.positionCurrent,
        size: this.positionSize,
      };
      getNewsPage(obj).then((res) => {
        this.positionList = res.data.records;
        this.total = res.data.total;
        this.positionList.forEach((item) => {
          item.active = false;
        });
      });
    },

    handleCurrentChange(e) {
      this.positionCurrent = e;
      this.getpositionList();
    },
    handleToDetail(tag) {
      this.$router.push({
        path: "./positionDetail",
        query: { id: tag.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.about_image {
  width: 100%;
  height: 411px;
}
.header_box {
  height: 120px;
  margin-top: -60px;
}
.title {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #2c8eff;
}
.title_padding {
  padding: 22px 0 0 62px;
}
.describe {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #e3e3e3;
  padding-left: 62px;
}
.position_box {
  height: 120px;
  background: #ffffff;
  border-left: 6px solid #2c8eff;

  .fc_92959A {
    color: #92959a;
  }
  .mt_detail {
    margin-top: 50px;
  }
}
.environment_box {
  // height: 354px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background: #ffffff;
  padding-bottom: 20px;
  .banner {
    margin: 17px;
    width: 301px;
    height: 283px;
  }
}
.text-overflow1 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #2c8eff;
}
</style>
